@import "../../../variables.scss";

#wrapper {
	color: $white-cyberpunk;
	transition: background-color 0.6s;
}

.noiseDiv {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-image: url("../../../../images/noise-texture-small.png");
	z-index: -1;
}

.initializeBackground {
	background-color: black;
	z-index: 1;
}

.contentCage {
	position: relative;
	z-index: -10;
}

.centeringContentCage {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	z-index: 0;
}
