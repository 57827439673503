@import "../../../../variables.scss";
@import "../../../../mixins.scss";

#projectRightText {
  position: absolute;
  left: 54%;
  top: 15%;
  height: 70%;
  width: 46%;

  transition: width 0.3s, left 0.3s;

  background-color: rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 26px 6px rgba(0, 0, 0, 0.4);

  @media screen and (max-width: 1325px), (max-height: $height-medium-device-1) {
    left: 40%;
    width: 60%;
  }

  @media screen and (max-width: $width-ipadpro-1), (max-height: $height-medium-device-1) {
    left: 30%;
    width: 70%;
  }

  @media screen and (max-width: $width-medium-device-0), (max-height: $height-medium-device-1) {
    left: 10%;
    width: 90%;
  }

  @media screen and (max-width: $width-medium-device-1), (max-height: $height-medium-device-1) {
    left: 4%;
    width: 96%;
  }

  .virtualTracker {
    width: 2px;
    background-color: white;
    opacity: 0.1;

    position: absolute;
    left: 16px;
    top: 0px;

    @media screen and (max-width: $width-medium-device-1), (max-height: $height-medium-device-1) {
      left: 11px;
    }
  }

  .projectLines {
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    background-color: #ffffff;
    box-shadow: rgb(196, 196, 196) 1px 0px 6px 0px;
  }

  #projectContent {
    display: inline-block;
    vertical-align: middle;
    padding-left: 30px;

    overflow-y: hidden;
    height: 100%;

    width: calc(100% - 57px);

    text-transform: uppercase;
    letter-spacing: 2px;

    @media screen and (max-width: $width-medium-device-1), (max-height: $height-medium-device-1) {
      padding-left: 10px;
      width: calc(100% - 20px);
    }

    @media screen and (max-width: $width-small-device-2), (max-height: $height-medium-device-1) {
      width: calc(100% - 20px);
    }

    .projectTittle {
      margin-top: 60px;

      &:first-child {
        margin-top: 0px;
      }

      padding: 10px 14px;
      font-weight: bold;

      font-size: 13px;
      letter-spacing: 3px;

      background: linear-gradient(90deg, rgba(236, 0, 77, 0.5) 0%, rgba(255, 255, 255, 0) 100%);

      clear: both;
      overflow: auto;

      @media screen and (max-width: $width-small-device-2), (max-height: $height-medium-device-1) {
        font-size: 12px;
      }

      .projectTittleLeft {
        text-align: left;
        float: left;
      }

      .projectTittleRight {
        text-align: right;
        float: right;
      }
    }

    .projectTopData {
      clear: both;
      overflow: auto;
      padding: 10px 14px;

      font-size: 12px;

      .projectTopDataLeft {
        text-align: left;
        float: left;
        width: calc(100% - 130px);

        .qrLink {
          display: block;
          height: 92px;
          width: 92px;
          margin-bottom: 37px;

          box-shadow: 0px 0px 8px 0px $gray-cyberpunk;

          @media screen and (max-width: $width-small-device-2), (max-height: $height-medium-device-1) {
            height: 62px;
            width: 62px;
          }

          .projectQrCode {
            image-rendering: pixelated;
            //background-image: url("../../images/projects/qrInvidia.png");
            background-size: contain;

            height: 100%;
            width: 100%;
            border: 1px solid white;

            transition: 0.3s;
            box-sizing: border-box;
          }
        }

        a {
          text-decoration: none;
          color: white;

          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          display: block;

          transition: 0.3s;

          &:hover {
            color: $red-cyberpunk;
          }
        }
      }

      .projectTopDataRight {
        text-align: right;
        float: right;
        width: 120px;

        @media screen and (max-width: $width-small-device-2), (max-height: $height-medium-device-1) {
          width: 100px;
        }

        .projectImageSite {
          width: 100%;
          box-shadow: 0px 0px 8px 0px $darkGray-cyberpunk;

          cursor: pointer;
          transition: 0.3s;
        }
      }
    }

    .projectHorizontalLine {
      background-color: #ffffff;
      height: 1px;
      width: calc(100% - 14px);
      box-shadow: rgb(196, 196, 196) 1px 0px 6px 0px;
    }

    .projectFoldCage {
      box-sizing: border-box;
      margin-top: 14px;
      width: calc(100% - 14px);
      border: 1px solid white;
      padding: 10px 14px;

      font-size: 13px;

      @include boxShadowWhiteOutsetInset;

      @media screen and (max-width: $width-small-device-2), (max-height: $height-medium-device-1) {
        font-size: 12px;
      }

      .projectTechnologyList {
        color: white;
      }

      .projectFoldTittle {
        margin-bottom: 10px;
      }

      a {
        text-decoration: none;
        color: white;
        transition: 0.3s;
        word-break: break-all;
        &:hover {
          color: $red-cyberpunk;
        }
      }
    }
  }
}
