//colors list
$light-gray: rgb(212, 212, 212);
$dark-gray: rgb(37, 37, 37);

$white-cyberpunk: #c4c4c4;
$gray-cyberpunk: #717676;
$darkGray-cyberpunk: #343434;
$red-cyberpunk: #ec004d;

//$red-dark-cyberpunk: blue;
$cyan-cyberpunk: #00f8f8;

//hud general
$margin-in-hud: 12px;

//media widths change

$width-ipadpro-1: 1025px;
$width-ipad-1: 800px;
$width-medium-device-0: 750px;
$width-medium-device-1: 600px;
$width-small-device-2: 520px;
$width-small-device-1: 400px;

//media heights change

$height-medium-device-1: 700px;
