@import "../../../../variables.scss";
@import "../../../../mixins.scss";

#startCenteredText {
  text-align: center;
  position: absolute;

  left: 50%;
  top: 48%;
  transform: translate(-50%, -50%);
  letter-spacing: 2px;

  width: 90%;

  text-transform: uppercase;

  :nth-child(1) {
    color: #ffffff;
    font-size: 30px;
    margin-bottom: 30px;
    text-shadow: 0px 0px 4px #c4c4c4;
    transition: 0.3s;

    @media screen and (max-width: 625px), (max-height: $height-medium-device-1) {
      font-size: 20px;
    }
  }
  :nth-child(2) {
    font-size: 15px;
    color: #ffffff;
    text-shadow: 0px 0px 4px #c4c4c4;
    transition: 0.3s;

    @media screen and (max-width: 625px), (max-height: $height-medium-device-1) {
      font-size: 11px;
    }
  }
}

#downTextScroll {
  position: absolute;
  bottom: 70px;

  letter-spacing: 2px;
  color: #bdbdbd;
  font-size: 18px;

  width: 100%;
  text-align: center;
  font-size: 15px;

  text-shadow: 0px 0px 4px #c4c4c4;

  transition: 0.3s;

  @media screen and (max-width: 625px), (max-height: $height-medium-device-1) {
    font-size: 11px;
  }
}
