@import "../../../../variables.scss";
@import "../../../../mixins.scss";

#contactLeftText {
  text-align: right;
  position: absolute;

  left: 0%;
  top: 24%;
  // transform: translateY(-50%);
  letter-spacing: 2px;

  width: 30%;
  min-width: 300px;
  max-width: 460px;

  text-transform: uppercase;

  transition: 0.3s;

  #contactTittle {
    @include textRedShadow;
    font-size: 26px;
  }

  #contactLine {
    width: 100%;
    height: 5px;
    background-color: #ffffff;
    box-shadow: rgb(196, 196, 196) 1px 1px 6px 0px;

    margin-top: 20px;
    margin-bottom: 28px;
  }

  div,
  a {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;

    font-size: 14px;
    text-shadow: 0px 0px 4px #c4c4c4;
    color: #ffffff;

    margin-top: 12px;
    margin-bottom: 12px;

    font-weight: lighter;
    text-decoration: none;

    @media screen and (max-width: 625px), (max-height: $height-medium-device-1) {
      font-size: 12px;
    }
  }

  a {
    transition: 0.3s;
  }

  a:hover {
    color: $red-cyberpunk;
    @include textRedShadow;
  }
}
